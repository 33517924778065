import React, { useCallback, useContext, useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import { transparentize } from 'polished'
import { func, node, number, shape, string } from 'prop-types'
import { Pie, PieChart } from 'recharts'

import LangContext from 'context/LangContext'
import SectorContext from 'context/SectorContext'

import { getCustomerOrders } from 'store/customers/actions'
import {
  currentYearInvoicedAmountForCustomer,
  customerFromUrl,
  customerOrders,
  recommendedQtyByTypeForCustomer
} from 'store/customers/selectors'
import { isDataKeyLoading } from 'store/dataFetches/selectors'
import { fetchPlanningSellin } from 'store/Sellin/actions'
import { planningSellinData } from 'store/Sellin/selectors'

import Card from 'components/card'
import EmptyState from 'components/EmptyState'
import SegmentControl from 'components/SegmentControl'
import { WrappedSpinner } from 'components/Spinner'

import { DATAKEY_TYPES, PLACEHOLDERS, PRODUCT_TYPE_OPTIONS } from 'utils/constants'
import { formatCompactNumber, formatCurrency, formatGap, formatPercent } from 'utils/formatters'
import { createDataKey, getErrorMessage } from 'utils/helpers'

import { accents } from 'styles/colors'

function getCircularData(completionPercentage, gapCloserCompletionPercentage) {
  const completed = completionPercentage
  const toDo = Math.max(1 - completed - gapCloserCompletionPercentage, 0)

  return [
    {
      name: 'completionPercentage',
      value: Math.min(completed, 1),
      fill: accents.orange
    },
    {
      name: 'gapCloser',
      value: gapCloserCompletionPercentage,
      fill: accents.red
    },
    {
      name: 'gapPercentage',
      value: Math.max(toDo, 0),
      fill: transparentize(0.8, accents.orange)
    }
  ]
}

const placeholderCircularData = [
  {
    name: 'completionPercentage',
    value: 0,
    fill: accents.orange
  },
  {
    name: 'gapPercentage',
    value: 100,
    fill: transparentize(0.8, accents.orange)
  }
]

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, name }) => {
  const centerX = cx
  const centerY = cy
  const RADIAN = Math.PI / 180
  const radius = innerRadius + (outerRadius - innerRadius) * 2

  const labelXPosition = centerX + radius * Math.cos(-midAngle * RADIAN)
  const labelYPosition = centerY + radius * Math.sin(-midAngle * RADIAN)

  if (name === 'Group B' && percent > 0) {
    const adjustedYPosition = (yOffset) => (labelYPosition < centerY ? labelYPosition - yOffset : labelYPosition)
    const textAnchor = labelXPosition > centerX ? 'start' : labelXPosition === centerX ? 'middle' : 'end'

    return (
      <g>
        <text
          x={labelXPosition}
          y={adjustedYPosition(20)}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#0f172a"
          fontSize={16}
          fontWeight={500}
        >
          50 ctn
        </text>
        <text
          x={labelXPosition}
          y={adjustedYPosition(0)}
          textAnchor={textAnchor}
          dominantBaseline="central"
          fill="#0f172a"
          fillOpacity={0.6}
          fontSize={14}
          fontWeight={500}
        >
          {`+${(percent * 100).toFixed(0)}%`}
        </text>
      </g>
    )
  }
}

const Graph = ({ completionPercentage, gapCloserCompletionPercentage }) => {
  const { translate } = useContext(LangContext)
  const circularData = getCircularData(completionPercentage, gapCloserCompletionPercentage)

  return (
    <div className="relative flex items-center justify-center">
      <PieChart width={300} height={300} margin={{ top: 64, right: 64, bottom: 64, left: 64 }}>
        <Pie
          data={circularData[0] === 0 ? placeholderCircularData : circularData}
          startAngle={270}
          endAngle={-90}
          dataKey="value"
          innerRadius="80%"
          outerRadius="100%"
          fill={[accents.orange, transparentize(0.8, accents.orange)]}
          isAnimationActive={false}
          labelLine={false}
          label={renderCustomizedLabel}
        />
      </PieChart>
      <div className="absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 flex-col items-center">
        <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.cycle')}</span>
        <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
          {formatPercent(completionPercentage, { convertDecimal: true })}
        </span>
        <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.ofTarget')}</span>
      </div>
    </div>
  )
}

Graph.propTypes = {
  completionPercentage: shape({}).isRequired,
  gapCloserCompletionPercentage: number
}

const DataLabel = ({ children }) => <span className="font-semibold text-slate-900">{children}</span>

DataLabel.propTypes = {
  children: node
}

const AddedValueCard = ({
  fetchPlanningSellin,
  getCustomerOrders,
  vapeCategory = 'all',
  unitOfMeasure = 'ctn',
  range = 'to_date',
  period = 'cycle',
  span
}) => {
  const { translate } = useContext(LangContext)
  const { currentSector, selectedLevel } = useContext(SectorContext)

  const [selectedProductType, setSelectedProductType] = useState(PRODUCT_TYPE_OPTIONS[0].value)
  const [error, setError] = useState()

  const { sectorId: customerId } = useParams()

  unitOfMeasure = selectedProductType === 'fmc' ? 'ctn' : 'ceq'

  const customerSellinData = useSelector((state) =>
    planningSellinData(state, {
      period,
      activeProductType: selectedProductType,
      vapeCategory,
      unitOfMeasure,
      range
    })
  )

  const employee = useSelector((state) => state.auth.user)
  const customer = useSelector((state) => customerFromUrl(state, { customerId }))
  const latestOrderRecaps = useSelector((state) => customerOrders(state, { customerId }))
  const planningSellinDataisLoading = useSelector((state) => isDataKeyLoading(state, { planningSellinDataKey }))
  const customerOrdersDataIsLoading = useSelector((state) => isDataKeyLoading(state, { customerOrderDataKey }))
  const recommendedQtyByType = useSelector((state) => recommendedQtyByTypeForCustomer(state, { customerId }))
  const currentYearInvoicedAmount = useSelector((state) => currentYearInvoicedAmountForCustomer(state, { customerId }))

  const recommendedQtyForSelectedType = recommendedQtyByType[selectedProductType].totalQty

  const planningSellinDataKey = createDataKey(DATAKEY_TYPES.PLANNING_SELL_IN, {
    period,
    sectorType: selectedLevel,
    sectorId: currentSector[selectedLevel]?.id,
    productType: selectedProductType,
    vapeCategory,
    unitOfMeasure,
    range
  })

  const customerOrderDataKey = createDataKey(DATAKEY_TYPES.CUSTOMER_ORDERS, { customerId })

  useEffect(() => {
    if (currentSector[selectedLevel]?.id) {
      setError()
      fetchPlanningSellin(
        {
          id: currentSector[selectedLevel].id,
          sectorLevel: selectedLevel,
          type: selectedProductType,
          vapeCategory,
          unitOfMeasure,
          period,
          range
        },
        planningSellinDataKey
      ).catch((error) => {
        setError(getErrorMessage(error))
      })
      getCustomerOrders({ customerId, customerOrderDataKey }).catch((error) => {
        setError(getErrorMessage(error))
      })
    }
  }, [currentSector, selectedLevel, selectedProductType, customerId])

  const totalProductsByCategory = useCallback(
    (entries, category) =>
      entries.filter((entry) => entry.product.category === category).reduce((total, entry) => total + entry.qty, 0),
    []
  )

  const renderContent = () => {
    const { actuals, gap, completionPercentage } = customerSellinData

    const todaysOrder =
      latestOrderRecaps.filter((order) => moment(new Date()).isSame(order.createdAt, 'day'))[0] || null

    const todaysOrderQtyByProductType =
      todaysOrder && todaysOrder.entries
        ? totalProductsByCategory(todaysOrder.entries, selectedProductType === 'vape' ? 'vype' : selectedProductType)
        : 0

    const finalTarget = actuals - gap

    const updatedActualsWithTodaysOrder = actuals + todaysOrderQtyByProductType
    const updatedCompletionPercentage = updatedActualsWithTodaysOrder / finalTarget
    const gapCloserCompletionPercentage = updatedCompletionPercentage - completionPercentage

    const updatedActualsWithReco = actuals + recommendedQtyForSelectedType
    const recoCompletionPercentage = updatedActualsWithReco / finalTarget

    function getNextOrderDates(nextOrderDates) {
      const today = new Date()
      const endDate = new Date(employee.currentCycle.endTime)

      return nextOrderDates.filter((date) => {
        const currentDate = new Date(date)
        return moment(currentDate).isBetween(today, endDate)
      })
    }

    const nextOrderDates = customer && customer.orderDates ? getNextOrderDates(customer.orderDates) : []

    if (planningSellinDataisLoading || customerOrdersDataIsLoading) return <WrappedSpinner icon="spinner" />

    if (error) return <EmptyState title={translate('app.warn.errorOccured')} subtitle={error} />
    const gapToGraph = Math.min(gapCloserCompletionPercentage, 1 - completionPercentage)

    return (
      <>
        <div className="grid grid-cols-6">
          <div className="col-span-3 row-start-2 flex w-full flex-col items-center justify-center gap-2 @xl/card:col-span-1 @xl/card:row-start-1 @4xl/card:col-span-2">
            <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.actuals')}</span>
            <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
              {actuals ? formatCompactNumber(actuals) : PLACEHOLDERS.NO_VALUE} ctn
            </span>
          </div>
          <div className="col-span-6 shrink-0 @xl/card:col-span-4 @4xl/card:col-span-2">
            <Graph completionPercentage={completionPercentage || 0} gapCloserCompletionPercentage={gapToGraph} />
          </div>
          <div className="col-span-3 flex w-full flex-col items-center justify-center gap-2 @xl/card:col-span-1 @4xl/card:col-span-2">
            <span className="block text-center text-2xs font-medium text-slate-500">{translate('common.gap')}</span>
            <span className="block min-w-fit text-center text-xl font-medium text-slate-900">
              {gap
                ? formatGap(gap, (val) => formatCompactNumber(val, { shouldStripIfInteger: true }))
                : PLACEHOLDERS.NO_VALUE}{' '}
              ctn
            </span>
          </div>
        </div>

        <hr className="my-2" />
        <ul className="flex list-none flex-col gap-2 pl-5 text-slate-700">
          <li>
            {completionPercentage > 0
              ? translate('evaluate.addedValue.cycleTargetCompletion.template', {
                  date: new Date().toDateString(),
                  completion: formatPercent(completionPercentage * 100)
                })
              : translate('evaluate.addedValue.noTargetForCycle')}
          </li>

          {todaysOrderQtyByProductType > 0 && completionPercentage > 0 && (
            <li>
              {translate('evaluate.addedValue.closingGapDetails.template', {
                amount: formatCompactNumber(todaysOrderQtyByProductType),
                newGap: formatPercent(updatedCompletionPercentage, { convertDecimal: true }),
                currentGap: formatPercent(completionPercentage, { convertDecimal: true }),
                gapChange: formatGap(gapCloserCompletionPercentage, (val) =>
                  formatPercent(val, { convertDecimal: true })
                )
              })}
            </li>
          )}

          {recoCompletionPercentage > 0 &&
            updatedCompletionPercentage > 0 &&
            recommendedQtyForSelectedType > todaysOrderQtyByProductType && (
              <li>
                {translate('evaluate.addedValue.closingGapDetailsVsReco.template', {
                  recoAmount: recommendedQtyForSelectedType,
                  currentCompletionPercentage: formatPercent(updatedCompletionPercentage, { convertDecimal: true }),
                  recoCompletionPercentage: formatPercent(recoCompletionPercentage, { convertDecimal: true })
                })}
              </li>
            )}

          {nextOrderDates.length > 0 && (
            <li>
              {translate('evaluate.addedValue.nextOrder.template', {
                dates: nextOrderDates.map((date) => moment(date).format('YYYY-MM-DD')).join(', ')
              })}
            </li>
          )}

          {currentYearInvoicedAmount && (
            <li>
              {translate('evaluate.addedValue.ytd.template', {
                invoicedAmount: formatCurrency(currentYearInvoicedAmount)
              })}
            </li>
          )}
        </ul>
        <span className="mt-auto text-2xs text-slate-500">{translate('evaluate.addedValue.disclaimer')}</span>
      </>
    )
  }

  return (
    <Card title={translate('evaluate.addedValue')} span={span} displayAmplify={false}>
      <SegmentControl
        key="range"
        name="type"
        onChange={(e) => setSelectedProductType(e.target.value)}
        value={selectedProductType}
        options={PRODUCT_TYPE_OPTIONS}
      />
      {renderContent()}
    </Card>
  )
}

AddedValueCard.propTypes = {
  fetchPlanningSellin: func,
  getCustomerOrders: func,
  vapeCategory: string,
  unitOfMeasure: string,
  range: string,
  period: string,
  span: shape({
    xs: number,
    sm: number,
    md: number,
    lg: number,
    xl: number
  })
}

const mapActionCreators = {
  fetchPlanningSellin,
  getCustomerOrders
}

export default connect(null, mapActionCreators)(AddedValueCard)
